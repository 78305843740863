<template>
  <div class="grjws">
    <div class="title">设备列表</div>
    <div>
      <div class="search">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="设备号:">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="在线状态:">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备厂商:">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区县:">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option> </el-select></el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
          :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
          :isNumber="table.isNumber" :formSearch="query">
          <!-- <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
          </div> -->
        </hltableZ>
      </div>
    </div>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "YanglaoSyr",
  components: { hltableZ },
  data() {
    return {
      query: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table: {
        columnEdit: true,
        column: [
          {
            label: "服务组织",
            prop: "oldName",
            checked: true,
          },
          {
            label: "业务类型",
            prop: "2",
            checked: true,
          },
          {
            label: "用户",
            prop: "organName",
            checked: true,
          },
          {
            label: "设备唯一识别码",
            prop: "oldName",
            checked: true,
          },
          {
            label: "联系人电话",
            prop: "2",
            checked: true,
          },
          {
            label: "设备名称",
            prop: "organName",
            checked: true,
          },
          {
            label: "设备型号",
            prop: "organName",
            checked: true,
          },
          {
            label: "紧急电话",
            prop: "organName",
            checked: true,
          },
          {
            label: "是否在线",
            prop: "oldName",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      searchData: {
        startDate: "",
        endDate: "",
      },
    };
  },

  mounted() { },

  methods: {
    resetForm() {
      Object.keys(this.searchData).forEach((k) => (this.searchData[k] = ""));
    },
  },
};
</script>

<style lang="scss" scoped>
.grjws {
  width: 100%;

  button {
    margin: 1rem;
  }

  .tableBox {
    height: 30rem;
    width: 100%;
  }
}

::v-deep .el-form {
  display: flex;
  align-items: center;
}

.title {
  width: 98%;
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin: 1rem auto;
  font-size: 1rem;
  color: rgb(39, 39, 39);
}
</style>